.subpage .main-content .pager-container {
  position: relative;
  display: flex;
  overflow: hidden;
  border: 1px solid #e6e6e6;
  align-items: baseline;
  margin-top: 20px;

  &:first-child {
    display: none;
  }

  >a {
    position: absolute;
    top: 0;
    z-index: 1;
    display: inline-block;
    padding: 12px 20px;
    width: 50px;
    background: #ffffff;
    border-right: 1px solid #e6e6e6;

    @media (min-width: 1025px) {
      padding: 15px 19px;
    }

    &:first-child {
      left: 0;
    }

    &:nth-child(2) {
      left: 50px;
    }

    &:nth-last-child(2) {
      right: 50px;
      left: auto;
      border-left: 1px solid #e6e6e6;
    }

    &:last-child {
      right: 0;
      left: auto;
      border-right: none;

    }

    .fa-caret-right,
    .fa-caret-left {
      font-size: 22px;
      line-height: .8;
    }

    &:hover,
    &:focus {
      color: #333333;
    }
  }

  ul.pager {
    display: flex;
    margin: 0;
    width: 100%;
    justify-content: center;


    li {
      display: none;
      padding: 0;
      border-right: 0;
      border-left: 0;
      text-align: center;

      @media (min-width: $large) {
        display: inline-block;
      }

      a {
        display: block;
        padding: 10px;
        color: #c7352e;
        text-decoration: none;
        font-weight: bold;
        font-family: 'Roboto', sans-serif;

        @media (min-width: 1025px) {
          padding: 15px;
        }

        &:hover,
        &:focus {
          color: #333333;
        }
      }

      &.active {
        display: inline-block;

        // background: #e6e6e6;

        a {
          color: #333333;
        }
      }

      &:before {
        content: none;
      }
    }
  }
}