.search-form {
    position: relative;
    top: rem-calc(-50px);
    z-index: 100;
    padding: 0 rem-calc(18px 30px);
    background: transparent;
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 38px, #000 100%);

    @media screen and (min-width: $large) {
        top: rem-calc(-225px);
        padding: rem-calc(90px 50px 30px);
        width: 100vw;

        // background: rgba(0, 0, 0, 0.82);
        // background: -o-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 98px, transparent 100%);
        // background: -o-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 98px, transparent 100%);
        // background:    linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 98px, transparent 100%);
        white-space: nowrap;
    }

    .filter-type {
        @include fontsize(12 12.5 35);

        display: flex;

        justify-content: center;

        @media screen and (min-width: $large) {
            justify-content: flex-start;
        }

        .filters-divider {
            display: inline-block;
            width: rem-calc(21px);
            color: rgba(255, 255, 255, 0.25);
            text-align: center;

            @media screen and (min-width: $large) {
                @include fontsize(24 25 50);
            }
        }

        button {
            @include fontsize(12 12.5 35);

            display: inline-block;
            margin: 0 0 rem-calc(15px) 0;
            padding: 0 0 5px 0;
            border: 0;
            border-bottom: 1px solid #000;
            background: transparent;
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 400;
            font-family: 'Roboto', sans-serif;
            cursor: pointer;

            @media screen and (min-width: $large) {
                @include fontsize(24 25 50);

                border-bottom: 1px solid transparent;
            }

            &.active,
            &:hover {
                border-bottom-color: #009ca9;
            }
        }
    }

    select {
        @include fontsize(18 25 0);

        display: block;
        margin-bottom: rem-calc(10px);
        padding: rem-calc(18px 15px);
        width: 100%;
        background: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #bfbfbf;
        background-position: calc(100% - 20px);
        font-weight: medium;
        font-family: 'Roboto', sans-serif;
        color: #000;
        -webkit-appearance: none;
        -moz-appearance: none;

        @media screen and (min-width: $large) {
            margin-right: rem-calc(10px);
            padding: rem-calc(11.5px 15px);
        }

        &:hover {
            cursor: pointer;
        }
    }

    .by-make,
    .by-body {
        display: inline-flex;
        width: 100%;

        flex-wrap: wrap;

        @media screen and (min-width: $large) {
            width: calc(100% - 154px);

            flex-wrap: nowrap;
        }
    }

    .by-make {

        .make,
        .model {
            display: block;
        }

        .style {
            display: none;
        }
    }

    .by-body {

        .make,
        .model {
            display: none;
        }

        .style {
            display: block;
        }
    }

    .btn.btn-search {
        @media screen and (min-width: $large) {
            display: inline-block;
            padding: rem-calc(15px);
            width: rem-calc(150px);
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    span.inventory-search-button {
        position: relative;
        display: inline;

        &:hover:after {
            position: absolute;
            bottom: 26px;
            left: -110px;
            z-index: 98;
            padding: 10px;
            width: auto;
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
            content: attr(data-title);
            text-transform: none;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;

            @media only screen and (max-width: 1024px) {
                bottom: 0;
                left: 50%;
                width: 200px;
            }
        }

        &:hover:before {
            position: absolute;
            bottom: 20px;
            left: 30px;
            z-index: 99;
            border: solid;
            border-width: 6px 6px 0 6px;
            border-color: #333 transparent;
            content: '';

            @media only screen and (max-width: 1024px) {
                display: none;
            }
        }

        &.hide-tooltip {

            &:hover:after,
            &:hover:before {
                display: none;
            }
        }
    }
}