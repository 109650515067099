.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  max-width: 780px;
  background: transparent;

  &:focus {
    outline: none;
  }

  // &.show-modal:after {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   z-index: -1;
  //   display: block;
  //   width: 100vw;
  //   height: 100vh;
  //   background: rgba(0, 0, 0, 0.5);
  //   content: '';
  //   transform: translate(-50%, -50%);
  // }

  .modal-content {
    max-height: 100vh;
    padding: 0;
    padding-bottom: 25px;
    margin-top: -28px;  
    background: white;    
    border-radius: 0;

    @media all and (min-width: $medium) {
      max-height: calc(100% - 20px);
      margin-top: -40px;
    }

    > *:not(.close-modal):not(h1) {
      padding: 20px;
      margin: 0;
      margin-top: -1px;
      text-align: left;
      background: #fff;

      @media all and (min-width: $medium) {
        padding: 10px 60px 5px;
      }
    }

    div:last-child {
      border-radius: 0 0 25px 25px;

      @media all and (min-width: $medium) {
        border-radius: 0;
      }
    }

    h1 {
      padding: 30px 25px;
      margin: 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
      color: #141d28;
      text-align: center;

      @media all and (min-width: $medium) {
        padding: 20px 60px;
      }
    }

    .close-modal {
      top: -20px;
      right: 10px;
      color: transparent;
      cursor: pointer;
      background: transparent;

      @media all and (min-width: $medium) {
        top: -30px;
      }

      &:after {
        position: absolute;
        top: 0;
        display: block;
        width: 25px;
        height: 25px;
        font-family: 'FontAwesome';
        font-weight: 900;
        font-size: 20px;
        color: #000;
        content: '\f00d';
        opacity: 0.5;
        transition: all 0.33s ease-in-out;
      }

      &:hover,
      &:focus {
        &:after {
          opacity: 1;
        }
      }
    }

    // &:after {
    //   position: absolute;
    //   bottom: -24px;
    //   left: 0;
    //   width: calc(100% - 40px);
    //   height: 25px;
    //   margin: 0 20px;
    //   background: #fff;
    //   border-radius: 0 0 25px 25px;

    //   @media all and (min-width: $medium) {
    //     content: '';
    //   }
    // }

    .modal-actions {
      border-radius: 0 0 20px 20px;

      @media all and (min-width: $medium) {
        border-radius: 0;
      }
    }
    
  }
}

