fieldset {
  padding: 0;
  border: 0;
  font-family: 'Roboto', sans-serif;

  label {
    display: block;
    text-transform: uppercase;
    font-weight: bold;
  }

  input,
  textarea,
  select {
    margin: 10px 0 40px 0;
    padding: 8px;
    border: 1px solid #cccccc;
    font-style: italic;
  }
}

input {
  padding: 15px 10px;
  border: 1px solid #cccccc;
}

select::-ms-expand {
  display: none;
}