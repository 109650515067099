.two-column,
.three-column,
.four-column {
@include medium-up {
        display: flex;
        margin: rem-calc(0 -27px);

        flex-wrap: wrap;
    }

    margin: 0;
    padding: 0;
    list-style: none;
    .col {
        width: 100%;
    }

    li {
        margin: 0;
        padding: 0;

        &:before {
            display: none;
        }
    }
}

.two-column .col {
    @include medium-up {
        padding: rem-calc(0 27px 27px 27px);
        width: 50%;
    }
}

.three-column .col {
    @include medium-up {
        padding: rem-calc(0 27px 27px 27px);
        width: 50%;
    }

@include large-up {
        width: 33.3%;
    }
}

.four-column .col {
    @include medium-up {
        padding: rem-calc(0 27px 27px 27px);
        width: 50%;
    }

@include large-up {
        width: 25%;
    }
}
