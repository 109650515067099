.recent-posts {
  display: inline-block;
  margin-top: 1.5625rem;
  width: 100%;
  letter-spacing: 0.001em;
  font-size: 1.125rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5rem;

  .post {
    padding: 3.125rem 0;
    border-bottom: 1px solid #cfcfcf;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    .title {
      h2 {
        margin: 0;
        color: #000000;
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 1.875rem;
      }
    }

    .date-author {
      margin: 10px 0 20px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
    }

    .readmore {
      margin: 20px 0 0;
    }
  }
}

.blog-detail {
  line-height: 1.875rem;

  h2 {
    margin-bottom: 0;
    color: #c7352e;
    font-weight: bold;
  }

  .pubdate {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
  }
}