.page-actions {
  &.re-align {
    margin-right: 0 !important;
  }
}

.jssocials {
  position: relative;
  // display: none;

  // transition: 1s all;

  &.show-icons {
    display: inline-block !important;

    @media only screen and (max-width: 480px) {
      display: block;
      float: none;
    }
  }

  .jssocials-shares {
    display: inline-flex;
    margin: 0 !important;

    a {
      color: #ffffff !important;
    }

    .jssocials-share {
      margin: 0 5px 0 0;

      // &:last-child {
      //   margin-right: 0.9375rem;
      // }
    }

    .jssocials-share-link {
      padding: 5px;
      color: white;

      &:hover {
        color: white !important;
      }

      .fa {
        margin: 0 !important;

        &:hover {
          color: white !important;
        }
      }
    }
  }
}
