footer.primary {
    padding: 40px 18px 60px;
    background: #f2f2f2;

    @media screen and (min-width: $large) {
        display: flex;
        padding: 40px 50px 60px;

        align-items: baseline;

        .contact {
            margin-right: 60px;
        }
    }
}
