body.show-menu {
  overflow: hidden;

  .wrap-nav {
    top: 0;
  }

  header {
    z-index: 101;
  }
}

.wrap-nav {
  position: absolute;
  top: -100vh;
  left: 0;
  z-index: 10;
  display: block;
  overflow-y: scroll;
  padding: 0 rem-calc(18px);
  width: 100vw;
  height: 100vh;
  background: #f2f2f2;

  // background: #000000;
  @media screen and (min-width: $large) {
    position: static;
    top: 0;
    display: inline-block;
    float: right;
    overflow: visible;
    margin-top: 0;
    margin-right: rem-calc(50px);
    padding: rem-calc(20px) 0 0 0;
    width: calc(100% - 430px);
    height: auto;
    white-space: nowrap;

    &.sticky {
      position: fixed;

      // top: rem-calc(-20px);
      top: -10px;
      right: 0;
      left: auto;
      z-index: 1000;
      background: transparent;

      .global-nav {
        // display: none;
      }
    }
  }

  .global-nav {
    margin: 0 rem-calc(-18px);
    padding: rem-calc(10px 18px);
    background-color: #333;

    @media screen and (min-width: $large) {
      position: relative;
      z-index: 2;
      margin: 0;
      padding: 1px 0;
      background: transparent;
      text-align: right;
    }

    a {
      @include fontsize(15 25 0);
      display: block;
      color: #fff;
      text-decoration: none;
      font-weight: medium;
      font-family: 'Roboto', sans-serif;

      @media screen and (min-width: $large) {
        @include fontsize(12 25 25);
        display: inline-block;
        margin: 0 rem-calc(10px);
        color: #bfbfbf;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .nav-primary.show-back-btn .nav-return {
    display: block;

    @media screen and (min-width: $large) {
      display: none;
    }
  }

  .nav-return {
    @include fontsize(14 25 50);
    display: none;
    margin: 0 rem-calc(-20px);
    padding: rem-calc(17px 20px);
    background: #c7352e;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;

    @media screen and (min-width: $large) {
      display: none;
    }

    .fa-angle-left:before {
      margin-right: rem-calc(10px);
      vertical-align: -2px;
      font-size: 1.35em;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    @media screen and (min-width: $large) {
      text-align: right;
    }

    //Don't show sub navigation by default
    ul {
      display: none;
    }

    //Base Nav Item Styling
    li {
      position: relative;
      z-index: 1;
      border-bottom: 1px solid #dbdbdb;
      text-align: left;

      .back-nav {
        display: none;
      }

      @media screen and (min-width: $large) {
        display: inline-block;
        margin: 0 rem-calc(10px);
        width: auto;
        border: 0;
        white-space: nowrap;

        .back-nav {
          top: rem-calc(17px);
          display: block;
          margin-bottom: 0;
          width: calc(100% - 20px);
          height: rem-calc(30px);
          border-bottom: 1px solid #dedede;
          color: #c7352e;
        }

        &.li1:hover {
          >ul {
            position: absolute;
            top: rem-calc(50px);
            left: rem-calc(-25px);
            display: block;
            width: rem-calc(215px);
            background: #fff;

            li {
              position: static;
              display: block;
              height: rem-calc(50px);
              border-bottom: 1px solid #dedede;

              a {
                @include fontsize(14 25 1);
                padding: 0;
                width: calc(100% - 55px);
                color: #c7352e;
                text-align: left;
                font-weight: bold;
                line-height: rem-calc(50px);
              }
            }
          }
        }

        &.li2 {
          &:hover {
            margin: 0;
            padding: 0 0.625rem;
            background: #c7352e;
            color: #fff;

            >a.a2 {
              color: #fff;

              &:after {
                color: #fff !important;
              }
            }
          }

          &.has_children {
            >a.a2 {
              &:after {
                position: absolute;
                top: 50%;
                right: -50px;
                z-index: 5;
                width: 3.125rem;
                height: 100%;
                color: #c7352e;
                content: '\f105';
                text-align: right;
                font-size: 1.5625rem;
                font-family: 'FontAwesome';
                transform: translateY(-50%);
              }
            }

            &.open-nav {
              >a {
                position: absolute;
                top: rem-calc(70px);
                z-index: 2;
                height: auto;
                color: #333;
                text-transform: capitalize;
                line-height: 1em;

                &:after {
                  content: none;
                }
              }

              >ul {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                padding-top: rem-calc(95px);
                padding-bottom: rem-calc(20px);
                min-height: 100%;
                width: 100%;
                background: #fff;

                &:before {
                  @include fontsize(14 50 1);
                  position: absolute;
                  top: 0;
                  left: 0;
                  margin: 0 rem-calc(10px 20px);
                  width: calc(100% - 20px);
                  height: rem-calc(50px);
                  border-bottom: 2px solid #c2c2c2;
                  color: #c7352e;
                  content: 'Back';
                  text-align: left;
                  text-indent: rem-calc(12px);
                  font-weight: bold;
                  font-family: 'Roboto', sans-serif;
                }

                &:after {
                  position: absolute;
                  top: rem-calc(12px);
                  left: rem-calc(10px);
                  color: #333;
                  content: '\f104';
                  font-size: rem-calc(21px);
                  font-family: FontAwesome;
                }

                li {
                  height: auto;
                  border: 0;

                  a {
                    margin: 0;
                    padding: 0;
                    width: calc(100% - 55px);
                    height: auto;
                    text-transform: capitalize;
                    font-weight: 400;
                    line-height: 1.75em;
                  }
                }
              }
            }
          }
        }
      }

      a {
        @include fontsize(18 25 0);
        position: relative;
        display: inline-block;
        padding: rem-calc(17px) 0;
        width: calc(100% - 55px);
        color: #c7352e;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;

        @media screen and (min-width: $large) {
          @include fontsize(14 25 50);
          width: 100%;
          color: #fff;
          font-weight: 400;
        }
      }

      &.hide-title {
        li:not(.open-nav) .a2:after {
          content: none !important;
        }

        >a {
          display: none;

          @media screen and (min-width: $large) {
            display: inline-block;
          }
        }
      }

      //If it has children then show the arrow indicating the next level
      &.has_children {
        cursor: pointer;

        &:after {
          position: relative;
          top: 0;
          right: rem-calc(-40px);
          width: rem-calc(50px);
          height: 100%;
          color: #333;
          content: '\f105';
          text-align: right;
          font-size: rem-calc(25px);
          font-family: 'FontAwesome';
          line-height: rem-calc(59px);

          @media screen and (min-width: $large) {
            content: none;
            line-height: rem-calc(50px);
          }
        }
      }

      &.li1.has_children>a:after,
      &.open-nav.has_children>a:after {
        @media screen and (min-width: $large) {
          content: none;
        }
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: rem-calc(60px);
        border-bottom: 0;

        @media screen and (min-width: $large) {
          margin-bottom: 0;
        }
      }
    }

    /*
          When the user chooses to go down to sub nav then:
            apply new styling to selected nav item
            show only the direct descendent of the current nav item
            display 'back to menu' button
        */
    &.active {
      >li:not(.open-nav) {
        display: none;

        @media screen and (min-width: $large) {
          display: inline-block;
        }
      }
    }

    li.open-nav {
      @media screen and (max-width: $large) {
        &:after {
          content: '';
        }

        >ul {
          display: block;
        }

        >a {
          margin: 0 rem-calc(-20px);
          padding-left: rem-calc(20px);
          width: calc(100% + 40px);
          background: #dedede;
          color: #333;
        }
      }
    }
  }
}

.nav-close,
.nav-open {
  @media screen and (min-width: $large) {
    display: none;
  }
}

.fa-close {
  position: absolute;
  top: rem-calc(13px);
  right: rem-calc(18px);
  display: none;
  color: #fff;

  //For whatever reason safari on ios was overwriting this with a default font size, thus the !important
  font-size: rem-calc(26px) !important;
}