.service-appointment {
  padding-top: rem-calc(50px);
  padding-bottom: rem-calc(60px);
  background: transparent url('/themes/isringhausen/assets/dist/images/mobile-service-bg.png') no-repeat center center;
  background-size: cover;

  @media screen and (min-width: $large) {
    display: flex;
    flex-direction: row-reverse;
    background-image: url('/themes/isringhausen/assets/dist/images/desktop-schedule-bg.jpg');

    align-items: center;
    justify-content: flex-end;

    .service-appt-text {
      width: rem-calc(700px);
    }

    .btn.btn-primary {
      margin-right: rem-calc(50px);
      width: rem-calc(450px);
      height: rem-calc(50px);
      white-space: nowrap;
    }
  }

  a {
    color: #333333;
    text-decoration: none;

    @include fontsize(18 30 0);

    &.btn {
      color: #ffffff;

      @include fontsize(18 18 0);
    }
  }
}

.testimonials {
  position: relative;
  padding-top: rem-calc(120px);
  padding-bottom: rem-calc(120px);

  @media screen and (min-width: $large) {
    min-height: 500px;
    height: auto;
  }

  .testimony {
    position: relative;
    top: 50%;
    bottom: 50%;
    z-index: 100;
    display: block;
    visibility: visible;
    margin: auto;
    padding: 0;
    opacity: 1;
    transform: none;

    @media screen and (min-width: $large) {
      position: absolute;
      height: rem-calc(250px);
    }

    blockquote {
      margin: 0;
      padding: 0;
      height: auto;

      @media screen and (min-width: $large) {
        position: relative;
        top: 50%;
        margin: 0 auto;
        width: rem-calc(945px);
        transform: translateY(-50%);
      }

      @media screen and (min-width: 1350px) {
        margin-left: rem-calc(300px);
      }

      &:before,
      &:after {
        position: absolute;
        display: block;
        width: rem-calc(130px);
        height: rem-calc(120px);
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        content: '';
      }

      &:before {
        top: rem-calc(-70px);
        left: rem-calc(-20px);
        background-image: url('/themes/isringhausen/assets/dist/images/quote-top.png');
      }

      &:after {
        right: rem-calc(-20px);
        bottom: rem-calc(-70px);
        background-image: url('/themes/isringhausen/assets/dist/images/quote-bottom.png');
      }

      .quote-attribution {
        @media screen and (min-width: $large) {
          float: left;
          margin-right: rem-calc(50px);
          margin-bottom: rem-calc(500px);
        }
      }

      .author {
        color: #ffb500;
        text-transform: uppercase;
        font-weight: 500;

        @include fontsize(19 23 50);
        font-family: 'Roboto', sans-serif;
      }

      .date {
        margin-top: -5px;
        color: #cccccc;
        font-weight: 400;

        @include fontsize(15 50 23);
        font-family: 'Roboto', sans-serif;
      }

      p {
        margin: 10px 0;
        color: #ffffff;
        font-weight: 500;

        @include fontsize(20 30 0);
        font-family: 'Merriweather', serif;

        @media screen and (min-width: $large) {
          position: relative;
          top: rem-calc(-10px);

          @include fontsize(30 45 1);
        }
      }
    }
  }
}

.motorsports {
  position: relative;
  padding: rem-calc(60px 18px);
  background: transparent url('/themes/isringhausen/assets/dist/images/mobile-motorsports-bg.jpg') no-repeat center center;
  background-size: cover;

  @media screen and (min-width: $medium) {
    padding-top: rem-calc(180px);
    padding-left: rem-calc(100px);
    height: rem-calc(750px);
    background-image: url('/themes/isringhausen/assets/dist/images/desktop-motorsports.jpg');
  }

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background: transparent url('/themes/isringhausen/assets/dist/images/stripes.png') no-repeat bottom left;
    content: '';

    @media screen and (min-width: $medium) {
      left: rem-calc(240px);
      background: transparent url(/themes/isringhausen/assets/dist/images/stripes-desktop.png) no-repeat bottom left;
    }
  }

  p {
    margin-top: rem-calc(40px);
    color: #ffffff;

    @include fontsize(18 30 0);
  }

  .motorsports-logo {
    max-width: calc(100% - 50px);
    width: rem-calc(300px);

    @media screen and (min-width: $medium) {
      width: rem-calc(300px);
    }
  }

  .page-content {
    position: relative;
    z-index: 2;

    @media screen and (min-width: $medium) {
      max-width: calc(100% - 80px);
      width: rem-calc(600px);
    }
  }

  .btn.btn-yellow {
    @media screen and (min-width: $medium) {
      width: rem-calc(300px);
    }
  }
}

.online-credit-application {
  padding-top: rem-calc(50px);
  padding-bottom: rem-calc(60px);

  // background: transparent url('/themes/isringhausen/assets/dist/images/mobile-service-bg.png') no-repeat center center;
  background-color: #e4e4e4;
  background-size: cover;

  @media screen and (min-width: $large) {
    display: flex;
    background-color: #e4e4e4;

    // background-image: url('/themes/isringhausen/assets/dist/images/desktop-schedule-bg.jpg');

    align-items: center;
    justify-content: flex-start;

    .online-credit-text {
      margin-right: rem-calc(50px);
      width: rem-calc(700px);
    }

    .btn.btn-primary {
      width: rem-calc(450px);
      height: rem-calc(50px);
      white-space: nowrap;
    }
  }

  a {
    color: #333333;
    text-decoration: none;

    @include fontsize(18 30 0);

    &.btn {
      color: #ffffff;

      @include fontsize(18 18 0);
    }
  }
}