html,
body {
    overflow-x: hidden;
    max-width: 100%;
    height: auto !important;
}

main {
    section.main-content {
        @media screen and (min-width: $large) {
            padding: rem-calc(0 30px);
        }
    }

    img {
        max-width: 100%;
    }
}

.homepage main {
    margin-top: rem-calc(-40px);

    @media screen and (min-width: $large) {
        margin-top: rem-calc(-185px);
    }

    section {
        padding-right: 20px;
        padding-left: 20px;

        @media screen and (min-width: $large) {
            padding-right: 50px;
            padding-left: 50px;
        }
    }
}

.subpage {
    main {
        margin: rem-calc(50px 0px);
        padding: 0;

        @media screen and (min-width: $large) {
            padding-right: 350px;
        }

        &.vehicle {
            padding: 0;

            @media screen and (min-width: $large) {
                margin-top: 40px;
            }
        }
    }

    section {
        padding-right: 20px;
        padding-left: 20px;

        @media screen and (min-width: $large) {
            padding-right: 50px;
            padding-left: 50px;
        }

        &.dealer-sites {
            img {
                width: 100%;
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                /* IE10+ Specific CSS styles */
                margin-top: 0;
            }

            @media screen and (min-width: $large) {
                padding-top: 85px;
                height: auto;

                // border-top: 1px solid #cccccc;

                img {
                    max-width: 100%;
                }
            }
        }

        &.testimonials {
            @media screen and (min-width: $large) {
                height: auto;
            }

            .testimony {
                @media screen and (min-width: $large) {
                    height: rem-calc(250px);
                }
            }
        }
    }

    .banner {
        img {
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
        }
    }
}
