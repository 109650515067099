.dealer-sites {
  @media screen and (min-width: $large) {
    display: flex;
    padding: 50px;

    align-items: center;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ Specific CSS styles */
    margin-top: -11.25rem;
  }

  h2 {
    @media screen and (min-width: $large) {
      margin-top: 0;

// margin-right: rem-calc(110px);
      margin-right: rem-calc(100px);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    column-count: 2;

    @media screen and (min-width: $large) {
      display: flex;

      align-items: center;
    }

    li.dealer-site {
      overflow: hidden;

// margin-right: 5px;
      margin-right: 5px;
      margin-bottom: 40px;

      a {
        position: relative;
        color: black;
        text-decoration: none;

        &:before {
          position: absolute;
          right: 0;
          bottom: 4px;
          width: 0;
          height: 0;
          border-top: 25px solid transparent;
          border-right: 25px solid #d3d3d3;
          content: '';
        }

        &:after {
          position: absolute;
          top: 3px;
          right: 4px;
          content: '\f054 \f054';
          letter-spacing: -2px;
          font-size: 8px;
          font-family: FontAwesome;
        }

        img {
          margin: 0 auto;
          padding: 20px;
          max-width: 200px;
          width: 100%;
          border: 1px solid #d3d3d3;
        }

        &:hover {
          color: white;

          img {
            border: 1px solid #c7352e;
          }

          &:before {
            border-right-color: #c7352e;
          }
        }
      }

      // width: rem-calc(155px);

      @media screen and (min-width: $large) {
        // margin: rem-calc(20px 60px 50px) auto;
      }

      // &.bmw {
      //   width: rem-calc(100px);
      // }

      // &.mercedes {
      //   width: rem-calc(82px);
      // }

      // &.porsche {
      //   width: rem-calc(155px);
      // }

      // &.volvo {
      //   width: rem-calc(82px);
      // }
    }
  }
}
