body {
    font-family: 'Roboto', sans-serif;
}

h2 {
    @include fontsize(25 24 50);

    margin-bottom: rem-calc(10px);
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
}

.btn {
    @include fontsize(18 18 0);

    display: block;
    width: 100%;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

    &.btn-search {
        padding: rem-calc(18px 15px);
        border: 1px solid #007d88;
        background-color: #007d88;
    }

    &.btn-primary {
        padding: rem-calc(15px 15px);
        border: 1px solid #003863;
        background-color: #003863;

        &:hover,
        &:focus {
            background-color: #333;
            border: 1px solid #333;

        }
    }

    &.btn-yellow {
        border-color: #ffb500;
        background-color: #ffb500;
        color: #333;
        text-decoration: none;
    }

    &:hover {
        cursor: pointer;
    }
}

p {
    @include fontsize(18 30 1);

    font-family: 'Roboto', sans-serif;
}

.subpage {
    h1 {
        @include fontsize(25 24 50);

        margin-bottom: rem-calc(30px);
        color: #003863;
        text-transform: uppercase;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
    }

    h2 {
        @include fontsize(25 24 50);

        margin-bottom: rem-calc(30px);
        color: #333;
        text-transform: uppercase;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;

        &.vehicle-heading {
            @include fontsize(24 30 10);

            color: #000;
            font-weight: 700;
        }
    }

    h3 {
        @include fontsize(25 24 25);

        margin-top: rem-calc(50px);
        margin-bottom: rem-calc(30px);
        color: #333;
        text-transform: uppercase;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
    }

    h4 {
        @include fontsize(18 30 50);

        margin-bottom: rem-calc(30px);
        color: #333;
        text-transform: uppercase;
        font-weight: 700;
        font-family: 'Roboto', sans-serif;
    }

    h5 {
        @include fontsize(18 30 1);

        margin-bottom: rem-calc(30px);
        color: #333;
        font-weight: 700;
        font-family: 'Roboto', sans-serif;
    }

    .main-content {
        .clear {
            display: block;
            clear: both;

            figure {
                padding-top: 0;
            }

            blockquote {
                display: inline-block;
            }
        }

        p {
            @include fontsize(18 30 1);

            color: #333;
            font-family: 'Roboto', sans-serif;
        }

        figure {
            @media screen and (max-width: $medium) {
                float: none !important;
                padding: 0;

                img {
                    margin: 0 auto;
                }
            }

            img {
                display: block;
            }

            figcaption {
                display: block;
                padding: 17px 0;
                border-bottom: 1px solid #ccc;
                color: #333;
                font-style: italic;
                font-size: 14px;
            }
        }

        .caption-image {
            margin-bottom: rem-calc(40px);
            width: 100%;

            img {
                width: 100%;
            }

            .caption {
                @include fontsize(14 30 1);

                display: block;
                padding: rem-calc(15px) 0;
                width: 100%;
                border-bottom: 2px solid #ccc;
                color: #333;
            }
        }

        blockquote {
            @include fontsize(27 36 1);

            margin-right: 0;
            margin-left: 0;
            padding: rem-calc(40px) 0;
            width: 100%;
            border-top: 5px solid #ffb500;
            border-bottom: 2px solid #ccc;
            font-weight: 300;
            font-style: italic;

            span {
                display: block;
                padding-top: 30px;
                font-size: 18px;

                strong {
                    font-weight: 900;
                }
            }

            &.bq-image {
                display: flex;

                flex-wrap: wrap;

                @media screen and (min-width: $medium) {
                    flex-wrap: nowrap;

                    >div {
                        margin: 20px 0;
                    }
                }

                figure {
                    width: 100%;

                    img {
                        margin: 0 auto;
                    }

                    @media screen and (min-width: $medium) {
                        min-width: 300px;
                    }
                }
            }
        }

        ul,
        ol {
            margin-left: rem-calc(25px);
            padding: 0;
            list-style-type: none;

            li {
                position: relative;

                // margin-bottom: rem-calc(25px);
                padding-bottom: rem-calc(25px);
                padding-left: rem-calc(20px);

                // border-bottom: 1px solid #cccccc;

                &:before {
                    position: absolute;
                    top: 9px;
                    left: 0;
                    content: '\25a0';
                    font-weight: 700;
                    font-size: rem-calc(12px);
                    line-height: 0;
                }

                &:last-child {
                    padding-bottom: rem-calc(25px);
                    border-bottom: 0;
                }
            }
        }

        ol {
            list-style: none;

            li {
                position: relative;
                counter-increment: li;

                &:before {
                    position: absolute;
                    top: 9px;
                    left: 0;
                    content: counter(li);
                    font-weight: 700;
                    font-size: rem-calc(18px);
                    font-family: 'Roboto', sans-serif;
                    line-height: 0;
                }
            }
        }

        a {
            color: #c7352e;
            cursor: pointer;
        }

        #rp_plugin ul li::before {
            content: '';
        }
    }
}

footer.primary {
    @include fontsize(14 20 0);

    font-family: 'Roboto', sans-serif;

    h2 {
        @include fontsize(14 20 25);

        margin: rem-calc(10px) 0 0 0;
        text-transform: uppercase;
        font-weight: bold;
        font-family: 'Roboto', sans-serif;
    }

    a {
        color: #c7352e;
        text-decoration: none;
    }

    .vcard {
        .org {
            margin: rem-calc(20px 0 10px 0);
            font-weight: bold;
        }
    }

    .footer-links {
        margin: rem-calc(10px 0 15px);

        a {
            display: block;
        }
    }

    .social-media {
        display: flex;
        align-items: center;
        @include fontsize(20 20 0);
        margin-bottom: rem-calc(20px);

        a {
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }

            .icn-tiktok {
                width: 16px;
            }
        }
    }

    .sales-hours,
    .service-hours {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
}