.content-form {
    position: relative;
    overflow: hidden;

    .form-header,
    .form-footer {
        padding-right: rem-calc(25px);
        padding-left: rem-calc(25px);

        p:empty {
            margin: 0;
        }
    }

    .form-footer {
        margin-top: rem-calc(30px);
    }

    .validation-summary {
        display: none;
    }

    fieldset {
        margin: rem-calc(0 0 30px 0);
        padding: 0;
        border: 0;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    label {
        position: relative;
        display: inline-block;
        clear: both;
    }

    input[type='text'],
    input[type='password'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='month'],
    input[type='week'],
    input[type='email'],
    input[type='number'],
    input[type='search'],
    input[type='tel'],
    input[type='time'],
    input[type='url'],
    textarea,
    select {
        position: relative;
        max-width: 100%;
        width: 100%;

             appearance: none;
        -moz-appearance: none;

        &:active,
        &:focus {
            // outline: 0;
        }
    }

    textarea {
        min-height: rem-calc(120px);
        height: auto;
    }

    .error-msg {
        position: relative;
        top: rem-calc(-30px);
        margin-top: 0;
        max-width: 100%;
        color: red;
    }

    .required {
        label:after {
            position: relative;
            top: -7px;
            margin-left: 2px;
            content: '\f069';
            text-decoration: inherit;
            font-weight: normal;
            font-style: normal;
            font-size: rem-calc(6px);
            font-family: FontAwesome;
        }
    }

    input[type='submit'] {
        display: inline-block;
        padding: 15px 60px;
        width: auto;
        border: 0;

        &:hover {
            background-color: #c7352e;
            cursor: pointer;
        }
    }

    /* small screens */
    .sm-full {
        float: left;
        clear: none;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
    }

    .sm-half {
        float: left;
        clear: none;
        padding-right: rem-calc(25px);
        padding-left: rem-calc(25px);
        width: 50%;
    }

    .sm-third {
        float: left;
        clear: none;
        padding-right: rem-calc(25px);
        padding-left: rem-calc(25px);
        width: 33.333%;
    }

    .sm-two-thirds {
        float: left;
        clear: none;
        padding-right: rem-calc(25px);
        padding-left: rem-calc(25px);
        width: 66.666%;
    }

    .sm-quarter {
        float: left;
        clear: none;
        padding-right: rem-calc(25px);
        padding-left: rem-calc(25px);
        width: 25%;
    }

    .sm-three-quarters {
        float: left;
        clear: none;
        padding-right: rem-calc(25px);
        padding-left: rem-calc(25px);
        width: 75%;
    }

    .sm-last+ div {
        clear: both;
    }

    .sm-last {
        padding-right: 0;
    }

    .sm-first {
        padding-left: 0;
    }

    /* medium screens */
    @media screen and (min-width: 40rem) {
        .sm-last+ div {
            clear: none;
        }

        .sm-last {
            padding-right: 0;
        }

        .md-full {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 100%;
        }

        .md-half {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 50%;
        }

        .md-third {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 33.333%;
        }

        .md-two-thirds {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 66.666%;
        }

        .md-quarter {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 25%;
        }

        .md-three-quarters {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 75%;
        }

        .md-last+ div {
            clear: both;
        }

        .md-last {
            padding-right: 0;
        }

        .md-first {
            padding-left: 0;
        }
    }

    /* large screens */
    @media screen and (min-width: 64rem) {
        .sm-last+ div {
            clear: none;
        }

        .sm-last {
            padding-right: 0;
        }

        .md-last+ div {
            clear: none;
        }

        .md-last {
            padding-right: 0;
        }

        .lg-full {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 100%;
        }

        .lg-half {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 50%;
        }

        .lg-third {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 33.333%;
        }

        .lg-two-thirds {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 66.666%;
        }

        .lg-quarter {
            float: left;
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 25%;
        }

        .lg-three-quarters {
            clear: none;
            padding-right: rem-calc(25px);
            padding-left: rem-calc(25px);
            width: 75%;
        }

        .lg-last+ div {
            clear: both;
        }

        .lg-last {
            padding-right: 0;
        }

        .lg-first {
            padding-left: 0;
        }
    }
}
