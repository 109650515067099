header.primary {
  position: relative;
  height: rem-calc(270px);
  background: #000;

  @media screen and (min-width: $large) {
    height: rem-calc(700px);
    overflow: hidden;
  }

  // &:before {
  //   display: block;
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   z-index: 1;
  //   height: rem-calc(250px);
  //   width: 100%;
  //   background: rgba(0, 0, 0, 0.82);
  //   background: linear-gradient(to bottom, rgba(0, 0, 0, 0.82) 0%, rgba(0, 0, 0, 0.82) 58px, transparent 100%);
  //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);

  //   @media screen and (min-width: $large) {
  //     height: rem-calc(280px);
  //     width: 100vw;
  //     background: rgba(0, 0, 0, 0.82);
  //     background: -o-linear-gradient(top, rgba(0, 0, 0, 0.82) 0%, rgba(0, 0, 0, 0.82) 58px, transparent 100%);
  //     background: linear-gradient(to bottom, rgba(0, 0, 0, 0.82) 0%, rgba(0, 0, 0, 0.82) 78px, transparent 100%);
  //   }
  // }

  // &:after {
  //   display: block;
  //   position: absolute;
  //   content: '';
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: rem-calc(110px);
  //   background: linear-gradient(to top, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0.82) 68px, transparent 100%);
  //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);

  //   @media screen and (min-width: $large) {
  //     background: transparent;
  //   }
  // }
}

.subpage {
  header.primary {
    background: black;
    height: 113px;

    @media screen and (min-width: $large) {
      background: white;
    }

    &:after,
    &:before {
      display: none;
    }
  }

  .banner {
    display: none;
  }

  main {
    margin-top: 0;
  }

  &.with-banner {
    header.primary {
      height: 113px;
      overflow: visible;

      @media screen and (min-width: $large) {
        // height: 350px;
        height: 400px;
      }

      &:before,
      &:after {
        content: none;

        @media screen and (min-width: $large) {
          content: '';
        }
      }

      .banner {
        height: 113px;
        width: 100%;
        overflow: hidden;
        background: transparent no-repeat center center;
        background-size: cover;
        display: block;

        @media screen and (min-width: $large) {
          // height: 350px;
          height: 400px;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          z-index: 2;
          background: rgba(0, 0, 0, .82);

          @media screen and (min-width: $large) {
            display: none;
          }
        }

        p {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;

          &:first-child {
            display: block;

            @media screen and (min-width: $large) {
              display: none;
            }
          }

          &:last-child {
            display: none;

            @media screen and (min-width: $large) {
              display: block;
            }
          }
        }

        img {
          width: 100%;
          height: auto;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          @media screen and (min-width: $large) {
            // height: 100%;
            // max-width: none;
            // right: 0;
            // width: auto;
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
          }
        }
      }
    }

    main {
      margin-top: 40px;

      @media screen and (min-width: $large) {
        margin-top: 100px;
      }
    }
  }

  &.show-menu header.primary {
    overflow: visible;
  }

  // .banner {
  //   height: 113px;
  //   width: 100%;
  //   overflow: hidden;
  //   background: url('/Resources/2bba3da5-696c-485a-a3bd-a6830628fdc8/style-guide-banner.jpg') no-repeat center center;
  //   background-size: cover;

  //   @media screen and (min-width: $large) {
  //     height: 350px;
  //     background-image: url('/Resources/f02b1cba-f2c6-4e39-a212-8d93a651d064/banner-desktop.jpg');
  //   }

  //   &:before {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     content: '';
  //     z-index: 2;
  //     background: rgba(0, 0, 0, .82);

  //     @media screen and (min-width: $large) {
  //       display: none;
  //     }
  //   }

  //   p {
  //     position: relative;
  //     width: 100%;
  //     height: 100%;
  //     padding: 0;
  //     margin: 0;

  //     &:first-child {
  //       display: block;

  //       @media screen and (min-width: $large) {
  //         display: none;
  //       }
  //     }

  //     &:last-child {
  //       display: none;

  //       @media screen and (min-width: $large) {
  //         display: block;
  //       }
  //     }
  //   }

  //   img {
  //     width: 100%;
  //     height: auto;
  //     position: absolute;
  //     top: 50%;
  //     transform: translateY(-50%);

  //     @media screen and (min-width: $large) {
  //       height: 100%;
  //       max-width: none;
  //       right: 0;
  //       width: auto;
  //     }
  //   }
  // }
}

.branding {
  position: relative;
  z-index: 1;
  padding: rem-calc(21px 18px) 0;
  height: rem-calc(156px);

  @media screen and (min-width: $large) {
    padding: rem-calc(30px 50px);
    display: inline-block;
    width: rem-calc(380px);

    &.sticky {
      // background: #333;
      background: rgba(0, 0, 0, .82);
      width: 100vw;
      // height: 50px;
      height: 100px;
      overflow: hidden;
      position: fixed;
      z-index: 999;

      a {
        position: relative;
        top: -12px;
        overflow: hidden;
        // height: 27px;
        height: 64px;
        display: inline-block;
      }

      .logo {
        position: relative;
        top: 0;
        width: 200px;
      }
    }
  }

  .logo {
    background: transparent url($assets + '/images/IsringhausenLogo.svg') no-repeat left center;
    background-size: contain;
    width: 56vw;
    min-height: 64px;
    display: inline-block;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ Specific CSS styles */
      width: 100%;
    }
  }
}

.nav-open {
  position: absolute;
  z-index: 1;
  right: rem-calc(18px);
  top: rem-calc(49px);
  color: #fff;
  @include fontsize(26 50 50);

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #fff;
    cursor: pointer;
  }
}

.hp-banner {
  width: 100%;
  top: -50px !important;
  height: 10rem;

  @media screen and (min-width: $large) {
    top: 0 !important;
    height: rem-calc(700px);
  }
}

.banner {
  position: absolute;
  top: 0;
  z-index: 0;
  height: rem-calc(270px);

  @media screen and (min-width: $large) {
    height: rem-calc(700px);
  }

  &.homepage {
    background: url(/themes/isringhausen/assets/dist/images/desktop-banner.jpg) no-repeat;
    height: 100%;
    background-size: cover !important;
    width: 100%;
    // background-position: left center !important;
    background-position: 50% !important;
  }

  img {
    width: 100%;
    min-height: 100%;

    @media screen and (min-width: $large) {
      height: 100%;
      min-height: 0;
      min-width: 100vw;
      max-width: none;
    }
  }

  iframe {
    display: none;
    position: absolute;
    width: 100vw;
    height: 100%;
  }

  .video-yes {
    overflow: hidden;
    position: relative;
    padding-bottom: 57.25%;
    padding-top: 0;
    height: 0;
    top: 0px;

    iframe {
      display: block;
    }
  }
}