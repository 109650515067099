html,
body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  background: transparent;
  font-size: 100%;
}

.clearfix:after {
  display: table;
  clear: both;
  content: '';
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a {
  color: blue;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:visited {
    color: blue;
  }

  &:hover,
  &:focus {
    color: purple;
    text-decoration: underline;
  }
}

input {
  // appearance: none;
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
}

.clear {
  zoom: 1;

  &:before,
  &:after {
    display: table;
    content: '';
  }

  &:after {
    clear: both;
  }
}
