.featured-news {
    padding: 0 0 25px !important;
    background: white;

    h2 {
        margin: 0;
        padding: 30px 20px;
        background: #e4e4e4;

        @media only screen and (min-width: $medium) {
            padding: 30px 50px;
        }
    }

    .featured-posts {
        display: flex;
        width: 100%;
        letter-spacing: 0.001em;
        font-size: 1.125rem;
        line-height: 1.5rem;

        @media only screen and (max-width: 1024px) {
            display: block;
            padding: 0;
        }

        .posts {
            position: relative;
            display: inline-block;
            overflow: hidden;
            padding: 25px 50px;
            width: 33%;
            vertical-align: top;
            text-align: center;

            @media only screen and (max-width: 1024px) {
                padding: 25px;
                width: 100%;

                &:after {
                    display: none;
                }
            }

            &:after {
                position: absolute;
                top: 15%;
                right: 0;
                height: 100%;
                border: 1px solid #c0c0c0;
                content: '';
            }

            &:last-of-type {
                // margin-right: -50px;

                &:after {
                    display: none;
                }
            }

            h3 {
                text-transform: uppercase;
                font-weight: 400;

                a {
                    color: #c7352e;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .pubdate {
                font-weight: bold;
                font-size: 16px;
            }
        }
    }

    .btn {
        margin: 30px auto;
        margin-left: 33%;
        width: 33%;
        color: white;
        text-decoration: none;

        @media only screen and (max-width: 1024px) {
            margin: 0 1.25rem;
            width: auto;
        }
    }
}

.news-detail {
    .headline {
        margin-bottom: 30px;
    }

    .pubdate {
        display: block;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
    }

    .featured-image {
        margin: 30px 0;
        width: 100%;

        @media screen and (min-width: $medium) {
            float: left;
            margin: 0 30px 30px 0;
            max-width: 45%;
        }

        img {
            width: 100%;
        }
    }

    .dealer-sites ul li.dealer-site a:after {
        top: -8px;
    }

    .back {
        margin-top: 60px;
        padding-top: 30px;
        border-top: 1px solid #ccc;

        a.back-to {
            text-decoration: none;
            text-transform: uppercase;

            &:before {
                margin-right: 5px;
                content: '\f137';
                font-family: FontAwesome;
            }
        }
    }

    .news-form {
        margin-top: 100px;
        width: 100%;
        max-width: 800px;
    }
}

.news-listings {
    @media only screen and (max-width: 768px) {
        text-align: center;
    }

    .post {
        display: inline-block;
        padding-bottom: 30px;
        width: 100%;

        .post-image {
            display: block;
            float: left;
            clear: both;
            overflow: hidden;
            margin: 10px 25px 10px 0;
            max-width: 200px;

            @media only screen and (max-width: 768px) {
                float: none;
                margin: 0 auto;
            }
        }

        .post-content {
            display: block;
            overflow: hidden;
            vertical-align: top;

            h2 {
                margin-bottom: 10px;

                a {
                    color: #c7352e;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .pubdate {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 16px;
            }

            .readmore {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
