.inside-inventory {
    margin: 0 auto;
    width: 100%;

    .inventory-sort-dropdown {
        padding: 20px;
        background: #e6e7e8;
    }

    table {
        margin: 0;
        border-spacing: 0;

        thead {
            background: #747476;

            th {
                padding: 0;
                color: #fff;
                text-transform: none;
                font-size: 16px;
            }
        }

        .make {
            padding: 0.5rem 0.25rem;
            background-color: #e6e7e8;
            color: black;
            text-align: left;

            .tablesaw-cell-label {
                display: none;
            }

            h2 {
                margin: 0;
                padding: 0 8px 0 40px;
                font-size: 18px;
            }
        }

        tr.vehicle {
            td {
                border-bottom: 1px solid #e6e7e8;
            }

            &:nth-child(odd) {
                background: #fafbfc;
            }

            &:nth-child(even) {
                background: #fff;
            }

            td.type {
                text-transform: uppercase;
            }

            a {
                color: black;
            }
        }

        td {
            padding: 2px 5px;
            border: none;
            font-size: 0.85rem;
        }
    }
}

@media print {
    .inventory-sort-dropdown {
        display: none;
    }

    td {
        font-size: 12px !important;
    }

    td.make {
        padding-top: 25px !important;

        h2 {
            padding-left: 0 !important;
            font-weight: 700;
        }
    }

    a {
        color: black;
        text-decoration: none;
    }
}
