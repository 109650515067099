$iebar-bg: #ffc410;
$iebar-border: #eab105;
$iebar-txt-top: #755603;
$iebar-txt-bottom: #4c3906;
$iebar-txt-bottom-hover: #222;

.ie-bar {
    padding: 1rem 0.9375rem;
    width: 100%;
    border-bottom: 5px solid $iebar-border;
    background: $iebar-bg;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Tahoma, Verdana, Segoe, sans-serif;

    p {
        @include fontsize(0.75 1.125);

        margin: 1px;
        padding: 0;
        color: $iebar-txt-top;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;

        a {
            display: block;
            color: $iebar-txt-bottom;

            &:hover {
                color: $iebar-txt-bottom-hover;
            }
        }
    }
}
