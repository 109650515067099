table {
    @include fontsize(18 24 1);

    font-family: 'Roboto', sans-serif;

    td {
        padding: rem-calc(24px) 0;
        border-right: rem-calc(10px) solid #fff;
    }

    thead {
        td {
            border-top: 5px solid #333;
            border-bottom: 1px solid #333;
            font-weight: bold;
        }
    }

    tbody {
        td {
            border-bottom: 1px solid #ccc;
        }

        tr:last-child td {
            border-bottom: 0;
        }
    }
}